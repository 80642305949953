import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { PrestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetPrestations, commitSetPrestation, commitRemovePrestation} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { INonConventionalHonorariumCreate } from '@/interfaces';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { IPrestationCreate } from '@/interfaces/prestations';

type MainContext = ActionContext<PrestationState, State>;

export const actions = {
    async actionGetPrestations(context: MainContext) {
        try {
            const response = await api.getPrestations(context.rootState.main.token);
            if (response) {
                commitSetPrestations(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async createPrestation(context: MainContext, payload: IPrestationCreate) {
        try {
            const loadingNotification = { content: 'opslaan', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createPrestation(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPrestation(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Tarief aangemaakt', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async deletePrestation(context: MainContext, payload: { id: number }) {
        try {
            const loadingNotification = { content: 'verwijderen', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deletePrestation(context.rootState.main.token, payload.id),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemovePrestation(context, payload.id);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Tarief verwijderd', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<PrestationState, State>('');

export const dispatchGetPrestations = dispatch(actions.actionGetPrestations);
export const dispatchCreatePrestation = dispatch(actions.createPrestation);
export const dispatchDeletePrestation = dispatch(actions.deletePrestation);
