import { IPrestation } from '@/interfaces/prestations';
import { PrestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setPrestations(state: PrestationState, payload: IPrestation[]) {
        state.prestations = payload;
    },
    setPrestation(state: PrestationState, payload: IPrestation) {
        const prestations = state.prestations.filter((prestation: IPrestation) => prestation.id !== payload.id);
        prestations.push(payload);
        state.prestations = prestations;
    },
    removePrestation(state: PrestationState, payload: number) {
        state.prestations = state.prestations.filter((prestation: IPrestation) => prestation.id !== payload);
    }
};

const { commit } = getStoreAccessors<PrestationState, State>('');

export const commitSetPrestation = commit(mutations.setPrestation);
export const commitSetPrestations = commit(mutations.setPrestations);
export const commitRemovePrestation = commit(mutations.removePrestation);
