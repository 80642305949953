import { PrestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    allPrestations: (state: PrestationState) => state.prestations,
    onePrestation: (state: PrestationState) => (prestationId: number) => {
        const filteredPrestations = state.prestations.filter((prestation) => prestation.id === prestationId);
        if (filteredPrestations.length > 0) {
            return { ...filteredPrestations[0] };
        }
    },
};

const { read } = getStoreAccessors<PrestationState, State>('');

export const readPrestation = read(getters.onePrestation);
export const readPrestations = read(getters.allPrestations);
